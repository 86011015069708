import React, {useState, useRef} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import Text from '../../typography';
import {ReactSVG} from 'react-svg';
import ReactHtmlParser from 'react-html-parser';

import {Cta, RespImg, Icon} from '../../ui';
import Dropdown from '../../ui/Dropdown';
import Modal from '../../ui/Modal';
import YoutubeVideo from '../YoutubeVideo';
import {theme} from '../../../themes/default-theme';
import topo from '../../../assets/topo.png';
import NextLink from '../../navigation/NextLink';
import playIcon from '../../../assets/icons/play.svg';
import withWidth, {ShowOnWidth} from '../../hoc/withWidth';
import {isWidthUp} from '@material-ui/core/withWidth';
import CategorySearch from '../../ui/CategorySearch'
import LazyVideo from '../LazyVideo';

const Container = styled.div`
  position: relative;
  max-width: ${theme.containers.full.width};
  margin: 0 auto;
  z-index: 0;
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
  }
  ${props => props.topo === 1 && props.slider === 1 && `
    padding-bottom: 40px;
    ${theme.breakpoints.up('md')} {
      padding-bottom: 60px;
    }
  `}
`

const ContentContainer = styled.div`
  position: relative;
  ${props => (props.topo && props.slider === 0) ? `
    margin-bottom: 40px;
    ${theme.breakpoints.up('md')} {
      margin-bottom: 60px;
    }
  ` : ''}
  &:before {
    content: '';
    width: 100%;
    display: block;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    background: linear-gradient(180deg, rgba(0,0,0,0.34) 0%, rgba(0,0,0,0) 100%);
  }
  ${props => props.topo && `
    &:after {
      content: ' ';
      position: absolute;
      height: 160px;
      z-index: -1;
      left: 0;
      bottom: -40px;
      display: block;
      background-image: url(${topo});
      background-size: 400px auto;
      background-repeat: repeat;
      background-position: center bottom;
      width: 100%;
      ${theme.breakpoints.up('md')} {
        bottom: -60px;
      }
    }
  `}
`

const TextContainer = styled.div`
  position: relative;
  z-index: 1;
  margin: 0 auto;
  h1 {
    margin: 10px auto;
    max-width: 1000px;
  }
  h6 {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
`

const Content = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0 ${theme.containers.main.gutter};
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  ${props => props.navType === 'mainNav' && `
    ${theme.breakpoints.up('md')} {
      // padding-top: 45px;
    }
  `}
  ${props => props.navType === 'subNav' && `
    padding-top: 45px;
    ${theme.breakpoints.up('md')} {
      padding-top: 45px;
    }
  `}
  ${props => props.alignTop === 1 && `
    top: 0;
    transform: none;
    padding-top: 50px;
    ${theme.breakpoints.down('sm')} {
      padding-top: 10px;
    }
  `}
  ${props => props.bannerSize === 'largeBanner' && `
    ${theme.breakpoints.down('sm')} {
      top: 20px;
      transform: none;
    }
    ${theme.breakpoints.up('lg')} {
      ${props.headingImage === 1 ? 'top: 50px' : 'top: 200px'};
      transform: none;
    }
  `}

  ${props => props.slider === 1 && `
    padding-left: 45px; 
    padding-right: 45px;
  `}
  
`

const Ctas = styled.div`
  padding: 0 ${theme.containers.main.gutter};
  text-align: center;
  margin-top: 20px;
  z-index: 2;
  ${props => props.topo === 1 && `
    margin-top: 60px;
  `}
  span {
    min-width: 220px;
  }
  ${props => (props.bannerSize === 'smallBanner' || props.bannerSize === 'mediumBanner' ||  props.bannerSize === 'largeBanner') && `
    font-size: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    ${theme.breakpoints.up('lg')} {
      bottom: 40px;
    }
  `}
  ${props => props.paddingBottom ? `
    padding-bottom: 80px;
  ` : `
    padding-bottom: 20px;
  `}
  ${props => props.staticPositioning === true && `
    position: static;
    margin-top: 20px;
  `}
  ${theme.breakpoints.up('md')} {
    font-size: 0;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: auto;
    ${props => props.staticPositioning && `
      position: static;
      margin-top: 20px;
    `}
  }
  ${props => (props.bannerSize === 'panel' || props.bannerSize === 'largeBanner') && !props.paddingBottom && `
    ${theme.breakpoints.down('md')} {
      bottom: 50px;
    }
  `}
  ${theme.breakpoints.up('lg')} {
    bottom: 70px;
  }
`

const Asset = styled.div`
  height: 0;
  padding-top: 33.333%;
  overflow: hidden;
  position: relative;
  // border-bottom-right-radius: 28px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  // ${theme.breakpoints.up('md')} {
  //   border-bottom-right-radius: 52px;
  // }
  // ${theme.breakpoints.up('lg')} {
  //   border-bottom-right-radius: 100px;
  // }
  picture,
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    width: auto;
    min-width: 100%;
    min-height: 100%;
  }
  ${props => props.bannerSize === 'smallBanner' && `
    padding-top: 18.125%;
    min-height: 290px;
    ${theme.breakpoints.down('sm')} {
      padding-top: 0;
      height: 100%;
      min-height: 180px;
    }
    ${theme.breakpoints.down('xs')} {
      min-height: 100px;
    }
  `}
  ${props => props.bannerSize === 'mediumBanner' && `
    padding-top: 26.25%;
    ${theme.breakpoints.down('md')} {
      padding-top: 37.5%;
    }
    ${theme.breakpoints.down('xs')} {
      padding-top: 0;
      height: 100%;
      min-height: 220px;
    }
  `}
  ${props => props.bannerSize === 'largeBanner' && `
    padding-top: 50%;
    ${theme.breakpoints.down('sm')} {
      padding-top: 0;
      height: 350px;
    }
  `}
  
`

const VideoWrapper = styled.div`
  height: 0;
  padding-top: 46.25%;
  ${props => props.bannerSize === 'smallBanner' && `
    padding-top: 18.125%;
    min-height: 245px;
    ${theme.breakpoints.down('sm')} {
      padding-top: 0;
      height: 100%;
      min-height: 180px;
    }
    ${theme.breakpoints.down('xs')} {
      min-height: 100px;
    }
  `}
  ${theme.breakpoints.up("md")} {
    height: auto;
    padding: 0;
  }

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
  }
`

const AssetContainer = styled.div`
  position: relative;
`

const CtaItem = styled.div`
  margin: 10px 0;
  ${props => (props.bannerSize === 'smallBanner' || props.bannerSize === 'mediumBanner' ||  props.bannerSize === 'largeBanner') && `
    margin: 5px 10px;
    display: inline-block;
  `}
  ${theme.breakpoints.up('md')} {
    margin: 10px;
    display: inline-block;
  }
`

const OverlayVideoContainer = styled.div`
  width: 100%;
  max-width: 1066px;
  margin: 0 auto;
`

const PlayBtn = styled.div`
  position: absolute;
  z-index: 3;
  left: 50%;
  margin: auto;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  ${props => props.bottomRightMobile && `
    bottom: 35px;
    bottom: 20px;
    top: auto;
    transform: none;
    left: auto;
    right: 20px;
  `}
  ${theme.breakpoints.up("sm")} {
    top: auto;
    transform: none;
    left: auto;
    right: 75px;
    bottom: 30px;
  }
  ${theme.breakpoints.up("md")} {
    bottom: 20px;
  }
  ${theme.breakpoints.up("lg")} {
    right: 100px;
    bottom: 77px;
    bottom: 50px;
  }
`

const TextImage = styled.img`
  display: block;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  height: auto;
`

const CategoryWrapper = styled.div`
  text-align: center;
`

const CategoryContainer = styled.div`
  text-align: center;
  position: relative;
  display: inline-block;

  ${props => props.search && `
    padding-right: 40px;

    ${theme.breakpoints.down("sm")} {
      display: flex;
      padding-right: 0;
    }
  `}

  ${props => props.visible === 0 && `
    opacity: 0;
  `}

  ${theme.breakpoints.up("md")} {
    padding-top: 24px;
  }
`

const Category = styled.a`
  color: white;
  font-size: 17px;
  text-decoration: none;
  cursor: pointer;
  
  ${theme.breakpoints.up("md")} {
    padding-left: 12px;
    padding-right: 12px;
    margin-left: 12px;
    margin-right: 12px;
    padding-bottom: 2px;
    &.category-current {
      border-bottom: 4px solid ${theme.palette.vividBlue}; 
    }
  }
`

const CategorySearchContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding-left: 20px;
  height: 29px;

  ${theme.breakpoints.down("sm")} {
    margin-top: 6px;
    padding-left: 15px;
  }
`

const DropdownContainer = styled.div`
  width: 100%;
  opacity: ${props => props.visible === 1 ? '1' : '0'};
`

const Banner = (props) => {

  const {
    mainHeading,
    headingSize,
    headingImage,
    subHeading1,
    subHeading2,
    ctas,
    categories,
    categorySearch,
    bgImage,
    type,
    bgVideoDesktop,
    bgVideoMobile,
    video,
    videoRef,
    ctaPosition,
    topo,
    navType,
    inline,
    categoryLocation,
    width,
    slider,
    id,
    fetchSearch,
  } = props;

  var height;

  switch (type.toLowerCase()) {
    case 'short':
      height = "smallBanner";
      break;
    case 'medium':
      height = "mediumBanner";
      break;
    case 'tall':
      height = "largeBanner";
      break;
    default:
      height = type;
  }

  const [modalOpen, openModal] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [showCategories, setShowCategories] = useState(true);
  
  if (activeDropdown) {
    window.open('/community/category/' + activeDropdown, "_self");
  }

  const inlineVideoRef = useRef(null);
  const vidRef = videoRef ? videoRef : inlineVideoRef

  const handleModal = (val) => {

    if (vidRef && vidRef.current) {
      if (!modalOpen) {
        vidRef.current.pause();
      } else {
        vidRef.current.play();
      }
    }
    openModal(val ? val : !modalOpen);
  }

  const PlayButton = () => (
    <PlayBtn bottomRightMobile={headingImage ? true : false}>
      <Icon onClick={() => handleModal()} play large>
        <ReactSVG src={playIcon} />
      </Icon>
    </PlayBtn>
  );

  return (
    <>
      {video &&
        <Modal
          isOpen={modalOpen}
          onClose={() => handleModal(false)}
        >
          <OverlayVideoContainer>
            <YoutubeVideo
              url={video}
              autoPlay
            />
          </OverlayVideoContainer>
        </Modal>
      }

      <Container topo={topo ? 1 : 0} slider={slider ? 1 : 0}>
        <ContentContainer topo={topo ? 1 : 0} slider={slider ? 1 : 0}>
          <Content bannerSize={height} slider={slider ? 1 : 0} headingImage={headingImage ? 1 : 0} navType={navType} alignTop={(ctas.length !== 0 && inline) ? 1 : 0 }>
            <TextContainer>
              {headingImage ?
                <TextImage src={headingImage.src} alt={headingImage.alt} />
              : <>
                {subHeading1 &&
                  <Text variant="h6" colour="white" align="center" uppercase={true}>{subHeading1}</Text>
                }
                {mainHeading &&
                  <Text variant="h1" colour="white" align="center" size={headingSize} uppercase={true}>{ReactHtmlParser(mainHeading)}</Text>
                }
                {subHeading2 &&
                  <Text variant="h6" colour="white" align="center" uppercase={true}>{subHeading2}</Text>
                }
              </>
              }
              {((ctas.length > 0 && headingImage) || (ctaPosition && ctaPosition === 'top')) &&
                <BannerCtas {...props} height={height} staticPositioning onCtaClick={props.onCtaClick} />
              }
              {categories.length > 0 &&
                <>
                  <ShowOnWidth show={isWidthUp('md', width)}>
                    <CategoryWrapper>
                      <CategoryContainer search={categorySearch}>
                          <NextLink href={'/community'}>
                            <Category
                              className={categoryLocation === '/community' && 'category-current'} 
                              dangerouslySetInnerHTML={{__html: 'All'}}
                            />
                          </NextLink>
                        {categories.map((item, index) => (
                          <NextLink key={index} href="/community/category/[item.slug]" as={`/community/category/${item.slug}`}>
                            <Category
                              className={categoryLocation === '/community/category/' + item.slug && 'category-current'} 
                              dangerouslySetInnerHTML={{__html: item.name}}
                            />
                          </NextLink>
                        ))}
                        {fetchSearch &&
                          <CategorySearchContainer>
                            <CategorySearch onActive={(e) => setShowCategories(e)} fetchSearch={fetchSearch}/>
                          </CategorySearchContainer>
                        }
                      </CategoryContainer>
                    </CategoryWrapper>
                  </ShowOnWidth>
                  <ShowOnWidth show={!isWidthUp('md', width)}>
                    <CategoryWrapper>
                      <CategoryContainer search={categorySearch}>
                        <DropdownContainer visible={showCategories ? 1 : 0}>
                          <Dropdown
                            options={categories.map((item, index) => {
                              return {
                                text: item.name,
                                value: item.slug
                              }
                            })}
                            onChange={e => setActiveDropdown(e.value)}
                          />
                        </DropdownContainer>
                        {fetchSearch &&
                          <CategorySearchContainer>
                            <CategorySearch onActive={(e) => setShowCategories(e)} fetchSearch={fetchSearch}/>
                          </CategorySearchContainer>
                        }
                      </CategoryContainer>
                    </CategoryWrapper>
                  </ShowOnWidth>
                </>
              }
            </TextContainer>
          </Content>

          <AssetContainer>
            <Asset bannerSize={height}>
              {bgImage && (!bgVideoDesktop || !bgVideoDesktop.src) && (!bgVideoMobile || !bgVideoMobile.src) &&
                <RespImg 
                  {...bgImage}
                  blur={true}
                />
              }

              {(bgVideoDesktop && bgVideoDesktop.src) && (bgVideoDesktop && bgVideoMobile.src) &&
                <VideoWrapper bannerSize={height}>
                  {isWidthUp('md', width) ?
                    <LazyVideo                   
                      attributes={{
                        muted:"true", 
                        autoPlay:"true", 
                        loop:"true", 
                        playsInline:"true"
                      }}
                      innerRef={vidRef} key={bgVideoDesktop.id || undefined}>
                        <source src={bgVideoDesktop.src} type={bgVideoDesktop.mimeType} />
                    </LazyVideo>
                  :
                    <LazyVideo                  
                      attributes={{
                        muted:"true", 
                        autoPlay:"true", 
                        loop:"true", 
                        playsInline:"true"
                      }} 
                      innerRef={vidRef} key={bgVideoMobile.id || undefined}>
                        <source src={bgVideoMobile.src} type={bgVideoMobile.mimeType} />
                    </LazyVideo>
                  }
                </VideoWrapper>
              }

              {/* {bgVideoDesktop && (bgVideo.mp4 || bgVideo.webm) &&
                <VideoWrapper>
                  <Video muted autoPlay loop ref={vidRef} playsInline>
                    {bgVideo.mp4 && <source src={bgVideo.mp4} type="video/mp4" /> }
                    {bgVideo.webm && <source src={bgVideo.webm} type="video/webm" /> }
                  </Video>
                </VideoWrapper>
              } */}

            </Asset>

            {video && 
              <PlayButton />
            }

          </AssetContainer>

        </ContentContainer>

        {((ctas.length > 0 && !headingImage) && (!ctaPosition)) &&
          <BannerCtas height={height} {...props} topo={topo ? 1 : 0} onCtaClick={props.onCtaClick} />
        }
      </Container>
    </>
  )
}

const BannerCtas = (props) => {
  return (
    <Ctas bannerSize={props.height} paddingBottom={props.slider} staticPositioning={props.staticPositioning ? true : false} {...props}>
      {props.ctas && typeof(props.ctas) === 'object' && props.ctas.map((cta, index) => (
        cta.href ?
          <CtaItem key={index} bannerSize={props.height}>
            <NextLink href={cta.href}>
              <a onClick={(e) => props.onCtaClick(cta)} target={cta.target}>
                <Cta component="span" bg={cta.color || null} secondary={index === 1 ? true : false} yellow={index === 2 ? true : false} inline>
                  {cta.title}
                </Cta>
              </a>
            </NextLink>
          </CtaItem>
        : null
      ))}
    </Ctas>
    )
}
  
Banner.propTypes = {
  mainHeading: propTypes.string,
  headingSize: propTypes.oneOf(['extraLarge', 'large', 'extraLargeLargeOnly']),
  headingImage: propTypes.object,
  type: propTypes.oneOf(['smallBanner', 'mediumBanner', 'largeBanner', 'panel', 'short', 'medium', 'tall']),
  subHeading1: propTypes.string,
  subHeading2: propTypes.string,
  ctas: propTypes.array,
  categories: propTypes.array,
  categorySearch: propTypes.bool,
  bgImage: propTypes.object,
  bgVideo: propTypes.object,
  videoRef: propTypes.object,
  video: propTypes.string,
  slider: propTypes.bool,
  ctaPosition: propTypes.string,
  topo: propTypes.bool,
  navType: propTypes.oneOf(['mainNav', null, 'subNav']),
  inline: propTypes.bool,
  categoryLocation: propTypes.string,
  onCtaClick: propTypes.func,
  id: propTypes.any,
  fetchSearch: propTypes.func,
}
  
Banner.defaultProps = {
  mainHeading: null,
  headingSize: 'large',
  headingImage: null,
  subHeading1: null,
  subHeading2: null,
  ctas: [],
  categories: [],
  categorySearch: false,
  type: 'panel',
  bgVideo: {},
  videoRef: null,
  video: null,
  slider: false,
  ctaPosition: null,
  topo: true,
  navType: 'mainNav',
  inline: false,
  categoryLocation: null,
  onCtaClick: () => {},
  id: null,
  fetchSearch: null,
}
  
export default withWidth(Banner)